import React, { useState } from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import DataTableToolbar from "./DataTableToolbar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/Table";
import DataTablePagination from "./DataTablePagination";
import { getCategoryOptions } from "../../lib/utils";
import { Transaction } from "../../lib/transactionSchema";
import { Separator } from "../ui/Separator";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  accountOptions: {
    label: string;
    value: string;
    icon?: React.ComponentType<{ className?: string }>;
  }[];
  sliceOptions: {
    label: string;
    value: string;
  }[];
  refreshTransactions: () => void;
  showingRecentTransactions: boolean;
  categories: { id: string; name: string }[];
  hiddenColumns?: string[];
  categoryOptions: {
    label: string;
    value: string;
  }[];
  onRowClick?: (transaction: Transaction) => void;
  onEditTransaction?: (transaction: Transaction) => void;
  onBulkDelete?: (transactions: Transaction[]) => void;
  onBulkAssign?: (transactions: Transaction[], sliceId: string) => void;
  onBulkUnassign?: (transactions: Transaction[]) => void;
  onBulkChangeCategory?: (
    transactions: Transaction[],
    categoryId: string
  ) => void;
  onBulkChangeDescription?: (
    transactions: Transaction[],
    description: string
  ) => void;
  slices: { id: string; name: string }[];
}

const DataTable = <TData, TValue>({
  columns,
  data,
  accountOptions,
  sliceOptions,
  refreshTransactions,
  showingRecentTransactions,
  categories,
  hiddenColumns,
  categoryOptions,
  onRowClick,
  onEditTransaction,
  onBulkDelete,
  onBulkAssign,
  onBulkUnassign,
  onBulkChangeCategory,
  onBulkChangeDescription,
  slices,
}: DataTableProps<TData, TValue>) => {
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    hiddenColumns
      ? Object.fromEntries(hiddenColumns.map((col) => [col, false]))
      : {}
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    meta: {
      refreshTransactions: refreshTransactions as () => void,
      onEditTransaction: onEditTransaction as () => void,
      onRowClick: onRowClick as () => void,
      categories: categories,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const selectedRows = table
    .getFilteredSelectedRowModel()
    .rows.map((row) => row.original);

  return (
    <div className="space-y-4">
      <DataTableToolbar
        table={table}
        accountOptions={accountOptions}
        sliceOptions={sliceOptions}
        showingRecentTransactions={showingRecentTransactions}
        categoryOptions={getCategoryOptions(categories)}
        selectedRows={selectedRows as Transaction[]}
        onBulkDelete={onBulkDelete}
        onBulkAssign={onBulkAssign}
        onBulkUnassign={onBulkUnassign}
        onBulkChangeCategory={onBulkChangeCategory}
        onBulkChangeDescription={onBulkChangeDescription}
        slices={slices}
        categories={categories}
      />
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onClick={() => onRowClick && onRowClick(row.original)}
                  className="cursor-pointer hover:bg-gray-100"
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
};

export default DataTable;
